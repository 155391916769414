:root { 
  --primary: #44bac1; 
  --primary-light:rgb(88, 193, 200);
  --primary-dark:rgb(59, 170, 176);
  --secondary: black;
  --secondary-dark: rgb(0, 0, 0);
  --secondary-light: rgb(0, 0, 0);
  --button-font-size:1rem;
  --button-font-size-desktop:1rem;
  --button-font-weight:400;
  --button-font-weight-desktop:400;
  --button-padding-r-l: 20px;
  --button-padding-r-l-desktop: 20px;
  --button-padding-t-b: 7px;
  --button-padding-t-b-desktop: 7px;
  --button-primary-color: #44bac1;
  --button-primary-color-hover: gray;
  --button-secondary-color: white;
  --button-secondary-color-hover: gray;  
  --button-third-color: black;
  --button-third-color-hover: rgb(0, 0, 0);
  --button-text-color-primary: white;
  --button-text-color-secondary: black;
  --button-text-color-third: white;
  --button-border-color-primary: transparent;
  --button-border-color-secondary: black;
  --button-border-color-third: transparent;
  --button-corner-radius: 0.125rem;
  --button-corner-radius-desktop: 0.125rem;
  --input-corner-radius: 0rem;
  --input-border-color: transparent;
  --input-background-color: white;
  --input-text-color: black;
  --input-padding-l-r: 8px;
  --input-padding-t-b: 8px;
  --font-base: Open sans;
  --font-title: Montserrat;
  --font-size-2xs:0.7125rem;
  --font-size-xs: 0.8125rem;
  --font-size-sm: 0.9375rem;
  --font-size-base: 1.0625rem;
  --font-size-lg: 1.1875rem;
  --font-size-xl: 1.3125rem;
  --font-size-2xl: 1.5625rem;
  --font-size-3xl: 1.9375rem;
  --font-size-4xl: 2.3125rem;
  --font-size-5xl: 3.0625rem;
  --font-size-6xl: 3.8125rem;
  --font-size-7xl: 4.5625rem;
  --font-size-8xl: 6.0625rem;
  --font-size-9xl: 8.0625rem;
}