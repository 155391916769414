@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  padding: 0;
  height: 100%;
  margin: 0;
}
#__next {
  height: 100%;
}

a {
  color: inherit;
  text-decoration: none;
}

/* .story_blok_rich_text h1 {
  font-size: 2em;
}
.story_blok_rich_text h2 {
  font-size: 1.5em;
}
.story_blok_rich_text h3 {
  font-size: 1.17em;
} */
/* .story_blok_rich_text h4 {
  font-size: 1em;
} */
.story_blok_rich_text h5 {
  font-size: 0.83em;
}
.story_blok_rich_text h6 {
  font-size: 0.67em;
}
.story_blok_rich_text span {
  display: inline-block;
}

* {
  box-sizing: border-box;
}
/* Hide scrollbar for Chrome, Safari and Opera */
.scrollbar-hidden::-webkit-scrollbar {
  display: none;
}

.clip-path-right-side {
  -webkit-clip-path: polygon(160px 0, 0 100%, 100% 100%, 100% 0);
  clip-path: polygon(160px 0, 0 100%, 100% 100%, 100% 0);
}

/* Hide scrollbar for IE, Edge add Firefox */
.scrollbar-hidden {
  -ms-overflow-style: none;
  scrollbar-width: none; /* Firefox */
}

.scrollbar-thin {
  /* Foreground, Background */
  scrollbar-color: #1e293b #0f172a;
}

.scrollbar-thin::-webkit-scrollbar {
  width: 3px; /* Mostly for vertical scrollbars */
  height: 3px; /* Mostly for horizontal scrollbars */
}
.scrollbar-thin::-webkit-scrollbar-thumb {
  /* Foreground */
  background: #ddd; /*rgb(154, 148, 227);*/
  border-radius: 10px;
}
.scrollbar-thin::-webkit-scrollbar-track {
  /* Background */
  background: transparent;
}

.react-horizontal-scrolling-menu--scroll-container::-webkit-scrollbar {
  display: none;
}

.react-horizontal-scrolling-menu--scroll-container {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.path {
  stroke: white;
  stroke-linecap: round;
  animation: dash 1.5s ease-in-out infinite;
}

.loader {
  -webkit-animation: spinner 1.5s linear infinite;
  animation: spinner 1.5s linear infinite;
}

.skeleton {
  animation: skeleton-loading 1s linear infinite alternate;
}

.vt-nested-link-preview-div {
  background-color: rgba(246, 230, 17, 0.9);
  width: 100%;
  height: 70px;
  padding-right: 10px;
  padding-left: 10px;
  text-align: center;
  color: black;
  font-weight: bold;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99999;
}

.animate-fade-in {
  animation-name: fadeIn;
  animation-duration: 0.5s;
}

.animate-height {
  max-height: 0;
  transition: max-height 0.25s ease-in;
  overflow: hidden;
}

.animate-height-2 {
  max-height: 500px;
  transition: max-height 0.25s ease-in;
}

.vt-button-custom-1337 {
  font-size: var(--button-font-size);
  font-weight: var(--button-font-weight);
}

@media (min-width: 1024px) {
  .vt-button-custom-1337 {
    font-size: var(--button-font-size-desktop);
    font-weight: var(--button-font-weight-desktop);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes skeleton-loading {
  0% {
    background-color: hsl(0, 0%, 95%);
  }
  100% {
    background-color: hsl(240, 45%, 98%);
  }
}

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes bouncy {
  0%,
  20%,
  50%,
  80%,
  100% {
    -webkit-transform: translateY(0);
  }
  40% {
    -webkit-transform: translateY(-8px);
  }
  60% {
    -webkit-transform: translateY(-4px);
  }
}
@-moz-keyframes bouncy {
  0%,
  20%,
  50%,
  80%,
  100% {
    -moz-transform: translateY(0);
  }
  40% {
    -moz-transform: translateY(-8px);
  }
  60% {
    -moz-transform: translateY(-4px);
  }
}
@-o-keyframes bouncy {
  0%,
  20%,
  50%,
  80%,
  100% {
    -o-transform: translateY(0);
  }
  40% {
    -o-transform: translateY(-8px);
  }
  60% {
    -o-transform: translateY(-4px);
  }
}
@keyframes bouncy {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-8px);
  }
  60% {
    transform: translateY(-4px);
  }
}

/* add delay at the end of animation so you dont start while another div is going */
@keyframes marquee {
  0% {
    left: 100%;
  }
  50% {
    left: -100%;
  }
  100% {
    left: -100%;
  }
}
